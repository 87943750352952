import axios, { AxiosRequestHeaders } from 'axios';

import env from '@core/env-config';
import storage from '@core/storage';

export const axiosWithAuth = axios.create({
  headers: {
    apiKey: env.INSURANCE_IN_A_BOX_KEY || '',
    authKey: storage().accessToken ?? ''
  }
});
axiosWithAuth.interceptors.request.use(
  function (config) {
    const { accessToken: token, authKey } = storage();
    config.headers = {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : ''
    } as AxiosRequestHeaders; // TODO: figure out how to add custom headers to AxiosRequestHeaders through interceptor in 1.6
    config.headers.apiKey = env.INSURANCE_IN_A_BOX_KEY || '';
    config.headers.authKey = authKey ?? '';

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
