import React, { PropsWithChildren, useEffect } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

import { useMessaging, useSelectFromMessageStore } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { useLandingPageNavigation } from '@features/landing-page';
import { useKillswitch } from '@features/onboarding';
import { DISCOUNT_CODES_BY_DISCOUNT } from '@features/safety-by-cilia';

import { Button, SejfaLogo, Toast } from '..';

const RootLayout = ({ children }: PropsWithChildren) => {
  const { push, pathname, query } = useRouter();
  const { clearMessage } = useMessaging();
  const { messages } = useSelectFromMessageStore((state) => state);
  const { navigateToOnboarding } = useLandingPageNavigation();

  const message = messages[0];

  const t = useTranslation('COMMON');
  const lt = useTranslation('LANDING_PAGE');
  const et = useTranslation('ERROR');
  const { addMessage } = useMessaging();

  const { isKillswitchEnabled } = useKillswitch();

  useEffect(() => {
    if (isKillswitchEnabled) {
      addMessage({
        message: et('KILLSWITCH_MSG')
      });
    }
  }, [isKillswitchEnabled]);

  //TODO: Get this value from store
  const isOnboardingSuccess = false;

  const handleCloseClick = () => {
    handleNavigateToRoot();
  };

  const isRootPath = pathname === '/';

  const isInOnboarding = pathname.includes('/onboarding');
  const isSafetyByCiliaPage =
    pathname.includes('/safety-by-cilia') && !pathname.includes('/success');

  //TODO: Use landing page navigation
  const handleSeePriceClick = () => {
    if (isSafetyByCiliaPage) {
      navigateToOnboarding(DISCOUNT_CODES_BY_DISCOUNT[40]);
    } else {
      push({
        pathname: '/onboarding',
        query
      });
    }
  };
  const handleNavigateToRoot = () => {
    if (!isRootPath) {
      push('/');
    }
  };

  const rightContainerClasses = twMerge(
    'flex items-center',
    isRootPath ? 'visible' : 'invisible',
    isSafetyByCiliaPage && 'visible'
  );

  const topBarClasses = twMerge(
    'h-24 flex-row items-center justify-between space-x-10 px-4 py-6 md:flex md:px-12',
    isRootPath && 'flex',
    isSafetyByCiliaPage && 'flex',
    isInOnboarding && 'hidden'
  );

  return (
    <>
      <Head>
        <title>Sejfa</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="no-scrollbar flex h-screen w-screen flex-col overflow-hidden">
        <div className={topBarClasses}>
          <button>
            <SejfaLogo
              onClick={handleNavigateToRoot}
              className={`w-24 ${
                isRootPath ? 'cursor-auto' : 'cursor-pointer'
              } md:w-24`}
            />
          </button>
          {isOnboardingSuccess && (
            <Button
              onClick={handleCloseClick}
              hugged
              className="bg-text text-white"
            >
              {t('CLOSE')}
            </Button>
          )}
          <div className={rightContainerClasses}>
            <Button
              onClick={handleSeePriceClick}
              hugged
              className="w-auto border-2 border-white bg-text px-4 py-3 text-white"
            >
              {lt('CALL_TO_ACTION')}
            </Button>
          </div>
        </div>
        <div className="no-scrollbar flex-1 overflow-auto rounded-2xl md:rounded-[32px]">
          {children}
        </div>
        {message && (
          <Toast
            message={message.message}
            onClose={() => clearMessage(message)}
            type={message.toastType}
          />
        )}
      </div>
    </>
  );
};

export default RootLayout;
