import React from 'react';

import { twMerge } from 'tailwind-merge';
type Props = {
  className?: string;
};

const Divider = ({ className }: Props) => {
  const baseClasses = twMerge('h-[1.5px] w-full', className ?? 'bg-disabled');

  return <hr className={baseClasses} />;
};

export default Divider;
