import React, { ReactNode, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { ActivityIndicator, Icon, IconType } from '..';

export type ButtonProps = {
  children?: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
  hugged?: boolean;
  icon?: IconType;
  iconClassName?: string;
} & React.ComponentProps<'button'>;

type Ref = HTMLButtonElement;

export const Button = forwardRef<Ref, ButtonProps>((props, ref) => {
  const {
    onClick,
    isLoading,
    children,
    variant = 'primary',
    hugged,
    icon,
    className,
    iconClassName,
    ...rest
  } = props;
  const baseClasses = [
    'active:bg-pressedPrimary',
    'disabled:bg-disabled disabled:text-grey',
    'rounded-full',
    'items-center',
    'justify-center',
    'flex',
    'font-bold',
    'text-[16px]',
    'min-h-[48px]',
    'text-text'
  ];

  const classes = twMerge(
    baseClasses,
    variant === 'primary' && 'bg-primary',
    variant === 'secondary' && 'bg-white border-2 border-black text-text',
    !hugged && 'w-full md:w-[383px]',
    hugged && 'w-[170px]',
    className
  );

  return (
    <button onClick={onClick} className={classes} ref={ref} {...rest}>
      {children}
      {isLoading && (
        <span className="ml-4">
          <ActivityIndicator />
        </span>
      )}
      {icon && !isLoading && (
        <Icon name={icon} className={twMerge('ml-4', iconClassName)} />
      )}
    </button>
  );
});
