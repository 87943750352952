import {
  ValidateTokenRequest,
  AuthResponse,
  CreateTokenResponse,
  CreateTokenParams
} from './types';
import { createRequest } from '../../api-definition';

export const createToken = createRequest<
  CreateTokenResponse,
  CreateTokenParams
>({
  path: 'Users/CreateToken',
  method: 'GET'
});

export const validateToken = createRequest<AuthResponse, ValidateTokenRequest>({
  path: 'Users/ValidateToken',
  method: 'POST'
});
