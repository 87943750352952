import React from 'react';

import { twMerge } from 'tailwind-merge';

export type TextInputProps = React.ComponentProps<'input'> & {
  floatingLabel?: string;
  icon?: IconType;
  onClick?: () => void;
  onIconClick?: () => void;
  containerClassName?: string;
  floatingLabelClassName?: string;
  mandatory?: boolean;
};

import { Icon, IconButton, IconType } from '..';

const TextInput = ({
  floatingLabel,
  className,
  icon,
  onClick,
  onIconClick,
  containerClassName,
  floatingLabelClassName,
  mandatory,
  ...props
}: TextInputProps) => {
  const baseClasses = twMerge(
    'peer block appearance-none bg-white px-0 pr-20 pt-[2px] pb-0 text-xl text-black focus:outline-none focus:ring-0 dark:text-black',
    onClick && 'cursor-pointer',
    className
  );
  const wrapperClasses = twMerge(
    'relative z-0',
    onClick && 'cursor-pointer hover:bg-disabled active:bg-pressedPrimary',
    containerClassName
  );

  const floatingLabelClasses = twMerge(
    'absolute top-1 origin-[0] -translate-y-6 scale-[0.6] transform text-base font-bold uppercase tracking-[0.72px] text-grey duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-[0.6] md:text-xl pointer-events-none',
    floatingLabelClassName
  );

  return (
    <div onClick={onClick} className={wrapperClasses}>
      <div className="absolute inset-y-0 right-2 flex items-center pl-3 pt-2">
        {typeof onIconClick === 'function' ? (
          <IconButton
            name={icon}
            onClick={onIconClick}
            className="bg-white hover:bg-disabled active:bg-pressedPrimary"
          />
        ) : icon ? (
          <Icon name={icon} />
        ) : null}
      </div>
      <input
        type="text"
        id="floating_standard"
        className={baseClasses}
        placeholder=" "
        {...props}
      />
      {!!floatingLabel && (
        <label htmlFor="floating_standard" className={floatingLabelClasses}>
          {floatingLabel} {mandatory && <span className="text-3xl">*</span>}
        </label>
      )}
    </div>
  );
};

export default TextInput;
