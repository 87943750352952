import { useRouter } from 'next/router';

export const useOnboardingNavigation = () => {
  const router = useRouter();

  const navigateToOnboardingFailed = (status: 'unavailable' | 'failed') => {
    router.push('/onboarding/failed' + '?status=' + status);
  };

  const navigateToOnboardingCancelled = () => {
    router.push('/onboarding/cancelled');
  };

  const navigateToRoot = () => {
    router.replace('/');
  };

  return {
    navigateToOnboardingFailed,
    navigateToOnboardingCancelled,
    navigateToRoot
  };
};
