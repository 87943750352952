import { PropsWithChildren } from 'react';

import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Document as RichTextDocument
} from '@contentful/rich-text-types';

interface RichTextProps {
  content: RichTextDocument;
}
// This component is used to render RichText from Contentful

const H3 = ({ children }: PropsWithChildren) => (
  <h3 className="text-[22px] font-medium">{children}</h3>
);

const H5 = ({ children }: PropsWithChildren) => (
  <h5 className="text-[18px] font-medium">{children}</h5>
);

const RichText: React.FC<RichTextProps> = ({ content }) => {
  const renderOptions: Options = {
    renderText: (text: string) => {
      return text
        .split('\n')
        .reduce<React.ReactNode[]>((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="center-marker mb-10 ml-8 mt-4 list-outside list-disc text-xl marker:text-[10px] marker:text-black">
          {children}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-base">{children}</p>
      ),
      [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
      [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>
    }
  };
  return (
    <div className="break-words">
      {documentToReactComponents(content, renderOptions)}
    </div>
  );
};

export default RichText;
