import { useEffect, useMemo, useState } from 'react';

import { GetTemplateResponse, HomeData, IIBError, mutations } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { Message } from '@core/types';
import { logger } from '@lib/logger';
import { capitalizeAll, convertNumericStringToNumber } from '@lib/utils';

import { SEND_DELAY } from './constants';
import { updateOnboardingStore, updateUserStore } from '../stores';

type ConversationStep = {
  step: number;
  messagesByInternalStep: {
    messages: Message[];
    sideEffect: () => void;
  }[];
};

export const useAddressConfirmation = (
  initialData?: GetTemplateResponse,
  isLoading?: boolean
) => {
  const updateProgress = updateOnboardingStore('updateProgress');
  const updateUser = updateUserStore('updateUser');
  const [messages, setMessages] = useState<Message[]>([]);
  const [step] = useState<number>(1);
  const [internalStep, setInternalStep] = useState<number>(0);
  const [canWrite, setCanWrite] = useState<boolean>(false);
  const [canChangeAddress, setCanChangeAddress] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const { addMessage: addToastMessage } = useMessaging();
  const tError = useTranslation('ERROR');

  const handleSimulationError = (error: IIBError) => {
    logger.error('Failed to simulate quote', error);
    addToastMessage({ message: tError('ONBOARDING.CALCULATING_FAILED') });
  };

  const { mutate: simulateQuote, isLoading: isLoadingSimulation } =
    mutations.quotes.useSimulateQuote({
      onSuccess: (data) => {
        if (!data?.result.isValidSimulation) {
          addToastMessage({
            message: tError('INVALID_POSTAL_CODE')
          });

          return;
        }
        setCanWrite(false);
        setCanChangeAddress(false);
        setTimeout(() => {
          updateProgress({ progressionStep: 2 });
        }, SEND_DELAY);

        if (step + 1 < totalSteps) {
          setTimeout(() => {
            setMessages([]);
          }, SEND_DELAY);
        }
      },
      onError: handleSimulationError
    });

  const mockConversation = useMemo<ConversationStep[]>(() => {
    if (isLoading) {
      return [
        {
          step: 1
        },
        {
          step: 2
        }
      ] as ConversationStep[];
    }
    if (initialData?.result?.homeData?.address) {
      return [
        {
          step: 1,
          messagesByInternalStep: [
            {
              messages: [
                {
                  id: '1',
                  type: 'recipient',
                  message: `Hej ${capitalizeAll(
                    initialData?.result?.userInfo?.firstName ?? ''
                  )}!`,
                  messageType: 'scripted',
                  contentType: 'text'
                },
                {
                  id: '2',
                  type: 'recipient',
                  message: `Är det till bostaden på ${initialData?.result?.homeData?.address} du vill ha försäkringen?`,
                  messageType: 'scripted',
                  contentType: 'text'
                }
              ],
              sideEffect: () => setCanChangeAddress(true)
            },
            {
              messages: [
                {
                  id: '3',
                  type: 'recipient',
                  message:
                    'Ingen fara, skriv bara in adressen som gäller här nedan.',
                  messageType: 'scripted',
                  contentType: 'text'
                },
                {
                  id: '4',
                  type: 'recipient',
                  message:
                    'Prisförslaget är delvis baserat på var du bor och kan komma att förändras.',
                  messageType: 'scripted',
                  contentType: 'text'
                }
              ],
              sideEffect: () => setCanWrite(!isDone)
            }
          ]
        },
        {
          step: 2
        }
      ] as ConversationStep[];
    }
    return [
      {
        step: 1,
        messagesByInternalStep: [
          {
            messages: [
              {
                id: '1',
                type: 'recipient',
                message: `Hej ${capitalizeAll(
                  initialData?.result?.userInfo?.firstName ?? ''
                )}!\nVilken adress vill du att försäkringen ska gälla för?`,
                messageType: 'scripted',
                contentType: 'text'
              },
              {
                id: '2',
                type: 'recipient',
                message:
                  'Prisförslaget är delvis baserat på var du bor och kan komma att förändras.',
                messageType: 'scripted',
                contentType: 'text'
              }
            ],
            sideEffect: () => setCanWrite(!isDone)
          }
        ]
      },
      {
        step: 2
      }
    ] as ConversationStep[];
  }, [
    initialData?.result?.homeData?.address,
    initialData?.result?.userInfo?.firstName,
    isLoading,
    isDone
  ]);

  const totalSteps = useMemo(
    () => mockConversation[mockConversation.length - 1].step,
    [mockConversation]
  );

  const addMessage = (message: Message) =>
    setMessages((prev) => [...prev, message]);

  useEffect(() => {
    if (!initialData?.result) {
      return;
    }
    let timeout: ReturnType<typeof setTimeout>;
    if (step) {
      const currentStep = mockConversation.find(
        (conversation) => conversation.step === step
      );

      if (step < totalSteps && currentStep) {
        const stepMessages =
          currentStep.messagesByInternalStep?.[internalStep]?.messages;

        if (messages?.length < stepMessages?.length) {
          timeout = setTimeout(() => {
            addMessage(stepMessages[messages.length]);
          }, SEND_DELAY);
        } else {
          setTimeout(() => {
            currentStep.messagesByInternalStep?.[internalStep]?.sideEffect?.();
          }, SEND_DELAY);
        }
      } else {
        setTimeout(() => {
          setIsDone(true);
        }, SEND_DELAY);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    initialData?.result,
    internalStep,
    messages.length,
    mockConversation,
    step,
    totalSteps
  ]);

  const sendMessage = (message: string, address?: string, zip?: string) => {
    const newZip = convertNumericStringToNumber(zip ?? '') ?? 0;
    simulateQuote({
      postalCode: newZip,
      numberOfResidents: 2,
      age: 25
    });
    addMessage({
      message,
      id: `${Math.random()}`,
      type: 'sender',
      messageType: 'scripted',
      contentType: 'text'
    });
    updateUser({
      address,
      postalCode: newZip
    });
    setIsDone(true);
  };

  const sendDefaultMessage = () => {
    const { address, postalCode } = initialData?.result?.homeData as HomeData;
    sendMessage(`${address}\n${postalCode}`, address, `${postalCode}`);
  };

  const goBack = () => {
    setInternalStep((prev) => prev - 1);
    const currentStep = mockConversation.find(
      (conversation) => conversation.step === step
    );
    setMessages(
      currentStep?.messagesByInternalStep?.[internalStep - 1]?.messages ?? []
    );
    currentStep?.messagesByInternalStep?.[internalStep - 1].sideEffect();
  };

  const changeAddress = () => {
    setInternalStep((prev) => prev + 1);
    setCanChangeAddress(false);
    setMessages([]);
  };

  return {
    messages,
    step,
    totalSteps,
    canWrite,
    canChangeAddress,
    isDone,
    sendMessage,
    goBack: internalStep > 0 || step > 1 ? goBack : undefined,
    changeAddress,
    sendDefaultMessage,
    isLoading: isLoadingSimulation
  };
};
