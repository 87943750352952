import { useRouter } from 'next/router';

export const useLandingPageNavigation = () => {
  const router = useRouter();

  const navigateToOnboarding = (discountCode?: string) => {
    if (discountCode) {
      router.push(`/onboarding?discount=${discountCode}`);
    } else {
      router.push('/onboarding');
    }
  };

  const navigateToRoot = () => {
    router.replace('/');
  };

  return {
    navigateToOnboarding,
    navigateToRoot
  };
};
