import { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import ChatbotSV from '@assets/images/sad.svg';
import { Block, Button, RadioButton, TextInput } from '@components/index';
import { useCancelledOffer } from '@core/api/';
import storage from '@core/storage';
import { logger } from '@lib/logger';
import { NextPageWithLayout } from '@pages/_app';

import SkipButton from '../components/SkipButton';
import { useOnboardingNavigation } from '../navigation';
import {
  resetStores,
  updateOnboardingStore,
  updatePriceStore,
  useSelectFromUserStore
} from '../stores';
import { useTrackEvent } from '../tracking';
import { useOnboardingTranslation } from '../translation';

// TODO: investigate if this could be moved to a modal in onboarding to not interrupt flow and enable continuing
const OnboardingCancelled: NextPageWithLayout = () => {
  const { navigateToRoot } = useOnboardingNavigation();
  const { push } = useRouter();
  const resetOnboarding = updateOnboardingStore('resetOnboarding');
  const resetPriceStore = updatePriceStore('resetPrice');

  const ot = useOnboardingTranslation();
  const { postalCode, email, firstName, lastName } = useSelectFromUserStore(
    (state) => state
  );

  const [userEmail] = useState(email ?? '');
  const [userZip] = useState(postalCode?.toString() ?? '');
  const [fullName] = useState(firstName ? `${firstName} ${lastName}` : '');

  const [selectedReasonIndex, setSelectedReasonIndex] = useState(0);
  const [reasonText, setReasonText] = useState('');
  const { trackEvent } = useTrackEvent();

  const trackOnboardingCancelled = useCallback(() => {
    return () => trackEvent('web_cancelled_onboarding');
  }, [trackEvent]);

  const { mutate: sendCancelledReason } = useCancelledOffer({
    onError: (error) => {
      logger.error(ot('CANCELLED_PAGE.ERROR_TEXT'), error);
    },
    onSuccess: () => {
      navigateToRoot();
    }
  });

  useEffect(() => {
    trackOnboardingCancelled();
  }, [trackOnboardingCancelled]);

  useEffect(() => {
    //TODO: this is called on initial rendering, find the cause of rerender to avoid duplicate state for user info
    return () => {
      resetOnboarding();
      resetPriceStore();
    };
  }, []);

  useEffect(() => {
    //TODO: this is called on initial rendering, find the cause of rerender to avoid duplicate state for user info
    return () => {
      storage().clearSessionStorage();
      resetStores();
    };
  }, []);

  const handleSubmit = () => {
    trackEvent('web_cancelled_onboarding_send');

    const payload = {
      email: userEmail,
      zip: userZip,
      name: fullName,
      reason: reasonText
        ? reasonText
        : CANCEL_REASONS[selectedReasonIndex].title
    };

    sendCancelledReason(payload);
  };

  const handleSkip = () => {
    trackEvent('web_cancelled_onboarding_skip');
    push('/');
  };

  const CANCEL_REASONS = [
    {
      id: 0,
      title: ot('CANCELLED_PAGE.OPTION_1'),
      onPress: () => setSelectedReasonIndex(0),
      isSelected: selectedReasonIndex === 0
    },
    {
      id: 1,
      title: ot('CANCELLED_PAGE.OPTION_2'),
      onPress: () => setSelectedReasonIndex(1),
      isSelected: selectedReasonIndex === 1
    },
    {
      id: 2,
      title: ot('CANCELLED_PAGE.OPTION_3'),
      onPress: () => setSelectedReasonIndex(2),
      isSelected: selectedReasonIndex === 2
    }
  ];

  return (
    <div className="grid h-full flex-auto gap-0.5 md:h-full md:grid-cols-2 md:grid-rows-1">
      <Block className="hidden items-center justify-center bg-pressedPrimary md:flex">
        <div className="column max-w-[425px] space-y-10 p-4 md:space-y-20 md:p-0">
          <div className="flex justify-center">
            <Image src={ChatbotSV} alt="sad-image" />
          </div>
          <div className="space-y-6">
            <h3 className="font-semibold">{ot('CANCELLED_PAGE.HEADER')}</h3>
          </div>
        </div>
      </Block>
      <Block className="flex h-full items-center justify-center bg-white">
        <div className="column flex max-w-[384px] flex-col justify-between p-4">
          <h5 className="my-6 font-semibold">{ot('CANCELLED_PAGE.HEADER')}</h5>
          <p className="font-semi-bold mb-12 text-xl ">
            {ot('CANCELLED_PAGE.PROVIDE_EMAIL')}
          </p>
          {CANCEL_REASONS.map((item) => (
            <RadioButton
              key={item.id}
              checked={item.isSelected}
              onChange={item.onPress}
              value={item.title}
            />
          ))}
          <TextInput
            id="other-reason"
            disabled={selectedReasonIndex !== 2}
            onChange={(e) => setReasonText(e.target.value)}
            placeholder={ot('CANCELLED_PAGE.TEXT_INPUT_PLACEHOLDER')}
            className="w-full border-b-2 border-black pl-0 pt-8"
            defaultValue={reasonText}
          />
          <Button
            onClick={handleSubmit}
            className="mb-2 mt-6 h-[72px] w-full bg-text text-white"
          >
            {ot('CANCELLED_PAGE.BUTTON_TITLE')}
          </Button>
          <SkipButton
            className="self-center duration-500 ease-in-out hover:opacity-60 active:opacity-100"
            onClick={handleSkip}
          />
        </div>
      </Block>
    </div>
  );
};

export default OnboardingCancelled;
