import { twMerge } from 'tailwind-merge';

import { Icon, IconType } from '..';

type Props = {
  label?: string;
  text: string;
  onClick?: () => void;
  icon?: IconType;
};

const TextWithLabel = ({ label, text, onClick, icon }: Props) => {
  return (
    <div
      className={twMerge(
        'cursor-pointer space-y-2',
        label ? 'mt-8 pb-4' : 'py-0',
        !onClick && 'pointer-events-none'
      )}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div>
          {label && (
            <label className="text-xs font-bold uppercase tracking-[0.72px] text-grey">
              {label}
            </label>
          )}
          <div className="text-2xl tracking-[0.3px]">{text}</div>
        </div>
        {icon && (
          <div className="p-3">
            <Icon className="w-8" name="question" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextWithLabel;
