import React from 'react';

import BalloonsImg from '@assets/images/balloons.webp';
import PizzaImg from '@assets/images/pizza.webp';
import { ImageBlock } from '@components/index';
import { usePriceSuggestionTranslation } from '@features/onboarding';

import CommonQuestionsBlock from './CommonQuestionsBlock';
import GadgetsAreSafeBlock from './GadgetsAreSafeBlock';
import GoodToKnowBlock from './GoodToKnowBlock';
import { ImportantDocumentsBlock } from './ImportantDocumentBlock';

const InformationListB = () => {
  const t = usePriceSuggestionTranslation();
  const information = [
    {
      title: t('INFORMATION_ONE.TITLE'),
      desc: t('INFORMATION_ONE.TEXT'),
      src: BalloonsImg
    },
    {
      title: t('INFORMATION_TWO.TITLE'),
      desc: t('INFORMATION_TWO.TEXT'),
      src: PizzaImg,
      list: [
        t('INFORMATION_TWO.LIST.ITEM_1'),
        t('INFORMATION_TWO.LIST.ITEM_2'),
        t('INFORMATION_TWO.LIST.ITEM_3'),
        t('INFORMATION_TWO.LIST.ITEM_4')
      ]
    }
  ];
  return (
    <div className="no-scrollbar rounded-2xl md:overflow-auto md:rounded-[32px]">
      <ul>
        {information.map((item, index) => (
          <li key={index} className="mb-0.5">
            <ImageBlock
              imageContainerClassName="md:justify-center md:pr-[0px] md:mb-8"
              contentContainerClassName="md:grid-cols-1 md:p-20"
              imageClassName="aspect-[343/229] w-full"
              src={item.src}
              title={item.title}
              desc={item.desc}
              list={item.list}
            />
          </li>
        ))}

        <li className="mb-0.5">
          <GoodToKnowBlock />
        </li>
        <li className="mb-0.5">
          <GadgetsAreSafeBlock />
        </li>
        <li className="mb-0.5">
          <CommonQuestionsBlock
            titleClassName="md:text-[40px] md:pb-6 pb-4"
            accordionTitleClassName="md:text-[24px] text-xl font-normal"
            dividerClassName="md:py-6 border-[#EBEDF0]"
            accordionTextClassName="md:text-xl"
          />
        </li>
        <li className="mb-0">
          <ImportantDocumentsBlock />
        </li>
      </ul>
    </div>
  );
};

export default InformationListB;
