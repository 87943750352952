import { IconButton, Modal } from '@components/index';
import { GeneralInsuranceInfoModalContentSkeletonEntry } from '@core/content-management';

import InformationBlock from './InformationBlock';

export type GeneralInfoText = {
  title?: string;
  body: string;
};

export type GeneralInfoData = {
  title: string;
  texts: GeneralInfoText[];
};

interface GeneralInfoModalProps {
  data?: GeneralInsuranceInfoModalContentSkeletonEntry;
  onClose?: () => void;
  moreInfo?: string;
}

export const GeneralInfoModal = ({
  onClose,
  data,
  moreInfo
}: GeneralInfoModalProps) => {
  return (
    <Modal>
      <div className="relative h-full">
        <InformationBlock className="max-w-6xl" title={data?.fields.title}>
          {data?.fields.texts.map((item) => (
            <>
              <p className="text-[24px] text-black ">{item?.fields.body}</p>
            </>
          ))}
          <h5 className="text-black">{moreInfo}</h5>
        </InformationBlock>
        {!!onClose && (
          <IconButton
            name="close"
            onClick={onClose}
            className="absolute right-4 top-4 bg-primary md:right-8 md:top-8"
          />
        )}
      </div>
    </Modal>
  );
};
