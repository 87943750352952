import React, { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { Block } from '@components/index';

type InformationBlockProps = {
  title?: string;
  className?: string;
  titleClassName?: string;
  containerClassName?: string;
};

const InformationBlock = ({
  title,
  className,
  titleClassName,
  containerClassName,
  children
}: PropsWithChildren<InformationBlockProps>) => {
  const baseClasses =
    'flex items-center bg-white px-8 pt-8 md:px-4 lg:px-40 md:px-20 md:py-20 md:justify-center';

  const classes = twMerge(baseClasses, className);
  const titleClasses = twMerge(
    'overflow-hidden break-normal text-[32px] font-semibold text-black md:text-[56px]',
    titleClassName
  );

  const containerClasses = twMerge(
    'grid grid-cols-1 gap-6 pb-8',
    containerClassName
  );

  return (
    <Block className={classes}>
      <div className="h-full w-full overflow-auto md:max-w-[1216px]">
        {title && <p className={titleClasses}>{title}</p>}
        <div className={containerClasses}>{children}</div>
      </div>
    </Block>
  );
};

export default InformationBlock;
