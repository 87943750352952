import { createTokenMock } from './mocks';
import { createToken } from '../requests';
import { createQueryWithMock } from '../store';

const useCreateToken = createQueryWithMock(
  'createToken',
  createToken,
  createTokenMock
);

const onboard = {
  useCreateToken
};

export default onboard;
