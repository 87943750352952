import React from 'react';

import { Block, Button } from '@components/index';
import { useTranslation } from '@core/translation';

import PriceInfo from './PriceInfo';
import { useOnboardingTranslation } from '../translation';

type PriceSuggestionBlockProps = {
  onContinueClick: () => void;
  onPayLaterClick?: () => void;
  onUpdateUserInfo?: () => void;
  price: string;
  fullPrice: string;
  priceUnit?: string;
  isLoading?: boolean;
  isButtonLoading?: boolean;
  isPayLater?: boolean;
  disabled?: boolean;
  discountCode?: string;
  insuranceInfo?: string;
  buttonText?: string;
  toggleInsuranceProviderModal?: () => void;
  isDiscountCodeEditable?: boolean;
  priceInfo?: string;
};

const PriceSuggestionBlock = ({
  onContinueClick,
  onUpdateUserInfo,
  fullPrice,
  price,
  priceUnit,
  disabled,
  discountCode,
  isButtonLoading,
  isPayLater,
  insuranceInfo,
  buttonText,
  isLoading,
  toggleInsuranceProviderModal,
  isDiscountCodeEditable,
  priceInfo
}: PriceSuggestionBlockProps) => {
  const t = useTranslation('COMMON');
  const ot = useOnboardingTranslation();

  const handleUpdateUserInfo = () => {
    onUpdateUserInfo?.();
  };
  return (
    <Block className="flex items-center justify-center overflow-visible bg-pressedTertiary md:overflow-hidden">
      <div className="flex h-full w-full flex-col justify-center p-4 md:px-20 md:py-12">
        <div className="flex h-full flex-col justify-center">
          <h3>{ot('PRICE_SUGGESTION_SCREEN.HOME_INSURANCE')}</h3>
          {insuranceInfo && (
            <p className="mb-4 mt-4 text-xl">{insuranceInfo}</p>
          )}

          <PriceInfo
            fullPrice={fullPrice}
            price={price}
            priceUnit={priceUnit}
            discountCode={discountCode}
            isDiscountCodeEditable={isDiscountCodeEditable}
            isLoading={isLoading}
            priceInfo={priceInfo}
          />
          {typeof onUpdateUserInfo === 'function' && (
            <Button
              icon="arrow-angle-up"
              onClick={handleUpdateUserInfo}
              className="mt-4 h-[40px] w-[240px] self-end bg-transparent"
              hugged
              disabled={disabled}
            >
              {ot('PRICE_SUGGESTION_SCREEN.CHANGE_YOUR_INFO')}
            </Button>
          )}
          <Button
            icon="question"
            onClick={toggleInsuranceProviderModal}
            className="mt-2 h-[40px] w-auto self-end bg-transparent px-4"
            hugged
          >
            {ot('PRICE_SUGGESTION_SCREEN.INFO_ABOUT_INSURER')}
          </Button>
        </div>
        <Button
          icon="arrow-forward"
          onClick={onContinueClick}
          className="mt-4 h-[64px] w-full md:h-[72px]"
          variant="secondary"
          hugged
          disabled={isButtonLoading || disabled}
          isLoading={isButtonLoading && !isPayLater}
        >
          {buttonText ?? t('NEXT')}
        </Button>
      </div>
    </Block>
  );
};

export default PriceSuggestionBlock;
