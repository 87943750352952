import { useState } from 'react';

import { Divider, TextWithLabel } from '@components/index';

import { GeneralInfoModal } from './GeneralInfoModal';
import InformationBlock from './InformationBlock';
import {
  GeneralInfoType,
  useGeneralInsuranceInfo
} from '../hooks/useGeneralInsuranceInfo';
import { useOnboardingTranslation } from '../translation';

const GoodToKnowBlock = () => {
  const t = useOnboardingTranslation();
  const [modalType, setModalType] = useState<GeneralInfoType | undefined>(
    undefined
  );

  const { data, moreInfo } = useGeneralInsuranceInfo(modalType);

  const closeGeneralInfoModal = () => {
    setModalType(undefined);
  };

  const openGeneralInfoModal = (type: GeneralInfoType) => {
    setModalType(type);
  };

  //TODO: Where do we get the values from?
  return (
    <>
      <InformationBlock
        title={t('PRICE_SUGGESTION_SCREEN.GOOD_TO_KNOW')}
        titleClassName="md:text-[40px] md:pb-4 md:pt-8"
        containerClassName="gap-2"
      >
        <div>
          <TextWithLabel
            label={t('PRICE_SUGGESTION_SCREEN.SEJF_VALUE')}
            text="1 000 000 kr"
            onClick={() => openGeneralInfoModal('INSURANCE_VALUE')}
          />
          <Divider />
        </div>
        <div>
          <TextWithLabel
            label={t('PRICE_SUGGESTION_SCREEN.DEDUCTIBLE')}
            text="1500 kr"
            onClick={() => openGeneralInfoModal('DEDUCTIBLE')}
          />
          <Divider />
        </div>
        <div>
          <TextWithLabel
            label={t('PRICE_SUGGESTION_SCREEN.SEJF_TRAVEL')}
            text="45 dagar"
            onClick={() => openGeneralInfoModal('TRIP_LENGTH')}
          />
          <Divider />
        </div>
      </InformationBlock>
      {!!modalType && (
        <GeneralInfoModal
          data={data}
          onClose={closeGeneralInfoModal}
          moreInfo={moreInfo}
        />
      )}
    </>
  );
};

export default GoodToKnowBlock;
