import { Fragment } from 'react';

import { Accordion, Divider } from '@components/index';
import { useWhenIsItSejfBlockContent } from '@core/content-management';

import InformationBlock from './InformationBlock';

const GadgetsAreSafeBlock = () => {
  const { data: sejfBlockData } = useWhenIsItSejfBlockContent();
  const sejfContent = sejfBlockData?.fields;

  return (
    <InformationBlock
      title={sejfContent?.title}
      titleClassName="md:text-[40px] md:pb-12 pb-6"
    >
      {sejfBlockData?.fields.dropdownBlocks.map((item) => (
        <Fragment key={item?.fields.title}>
          <Accordion
            title={item?.fields.title ?? ''}
            subtitle={item?.fields.subtitle}
            content={item?.fields.texts}
            titleClassName="md:text-[24px] text-xl font-normal"
          />
          <Divider />
        </Fragment>
      ))}
    </InformationBlock>
  );
};

export default GadgetsAreSafeBlock;
