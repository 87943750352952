import React, { useEffect, useRef } from 'react';

export const TrustPilotWidget = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className="inline-block text-left">
      <div
        className="trustpilot-widget"
        data-locale="sv-SE"
        ref={ref}
        data-template-id="5419b637fa0340045cd0c936"
        data-businessunit-id="646f61b94121be1c9a92a8f3"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://se.trustpilot.com/review/sejfa.nu"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
