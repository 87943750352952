import { useCallback, useState } from 'react';

import { usePriceSuggestionSimplifiedChatContent } from '@core/content-management';
import { LINKS } from '@core/links';
import { Message } from '@core/types';
import {
  ACCOMODATION_TYPE,
  OnboardingChatForm,
  resetStores,
  useTrackEvent
} from '@features/onboarding';
import { uuid } from '@lib/utils';

import { useOnboardingNavigation } from '../navigation';
import { useOnboardingTranslation } from '../translation';

export const usePriceSuggestionSimplifiedChatMessages = (
  sendMessage?: (message: string, accomodationType?: ACCOMODATION_TYPE) => void
) => {
  const {
    data: priceSuggestionChatData,
    loading,
    error
  } = usePriceSuggestionSimplifiedChatContent();
  const tOnboarding = useOnboardingTranslation();
  const { trackEvent } = useTrackEvent();

  const { navigateToRoot } = useOnboardingNavigation();
  const [accomodationType, setAccomodationType] = useState<ACCOMODATION_TYPE>();

  const priceSuggestionChatContent = priceSuggestionChatData?.fields;

  const trackOnboardingCancelled = useCallback(() => {
    return () => trackEvent('web_cancelled_onboarding');
  }, [trackEvent]);

  const handleSelectIsRenting = (text: string, index: number) => {
    const isRenting = index === 3;
    if (isRenting) {
      sendMessage?.(text);
    } else {
      trackOnboardingCancelled();
      resetStores();
      navigateToRoot();
    }
  };

  const handleSelectAccomodationType = (text: string, index: number) => {
    if (index === 4) {
      setAccomodationType(ACCOMODATION_TYPE.HOUSE);
      sendMessage?.(text, ACCOMODATION_TYPE.HOUSE);
    } else {
      setAccomodationType(ACCOMODATION_TYPE.APARTMENT);
      sendMessage?.(text, ACCOMODATION_TYPE.APARTMENT);
    }
  };
  const ACCOMODIATION_TYPE_TEXTS = [
    tOnboarding('ACCOMODATION_TYPE.TEXT_1'),
    tOnboarding('ACCOMODATION_TYPE.TEXT_2'),
    tOnboarding('ACCOMODATION_TYPE.TEXT_3'),
    tOnboarding('ACCOMODATION_TYPE.TEXT_4'),
    tOnboarding('ACCOMODATION_TYPE.TEXT_5')
  ];

  const HOUSE_TYPE_TEXTS = [
    tOnboarding('HOUSE_TYPE.TEXT_1'),
    tOnboarding('HOUSE_TYPE.TEXT_2'),
    tOnboarding('HOUSE_TYPE.TEXT_3'),
    tOnboarding('HOUSE_TYPE.TEXT_4')
  ];

  const t = useOnboardingTranslation();

  const messages: {
    step?: number;
    placeholder: (count?: number) => string;
    key: keyof OnboardingChatForm;
    messages: Partial<Message>[]; // Temporary solution
  }[] = [
    {
      placeholder: () => '',
      key: 'accomodationType',
      messages:
        ACCOMODIATION_TYPE_TEXTS.map((text, index) => {
          if (index < 3) {
            if (index === 1) {
              return {
                id: uuid(),
                type: 'recipient',
                messageType: 'scripted',
                contentType: 'text',
                message: text.replace(
                  'personuppgifter och vår cookiepolicy',
                  ''
                ),
                linkText: 'personuppgifter och vår cookiepolicy',
                link: LINKS.YOUR_INTEGRITY
              };
            }

            return {
              id: uuid(),
              type: 'recipient',
              messageType: 'scripted',
              contentType: 'text',
              message: text
            };
          }
          return {
            id: uuid(),
            type: 'sender',
            messageType: 'scripted',
            contentType: 'action',
            message: text,
            action: () => handleSelectAccomodationType(text ?? '', index)
          };
        }) ?? []
    },
    {
      placeholder: () => '',
      key: 'isRenting',
      messages:
        HOUSE_TYPE_TEXTS.map((text, index) => {
          const newMessage: Partial<Message> =
            index < 2
              ? {
                  id: uuid(),
                  type: 'recipient',
                  messageType: 'scripted',
                  contentType: 'text',
                  message: text
                }
              : {
                  id: uuid(),
                  type: 'sender',
                  messageType: 'scripted',
                  contentType: 'action',
                  message: text,
                  action: () => handleSelectIsRenting(text ?? '', index)
                };
          return newMessage;
        }) ?? []
    },
    {
      placeholder: () => t('PRICE_SUGGESTION_CHAT.YEAR').toLowerCase(),
      key: 'age',
      messages:
        priceSuggestionChatContent?.ageTexts?.map((text, index) => {
          const newMessage: Message = {
            id: uuid(),
            type: 'recipient',
            messageType: 'scripted',
            contentType: 'text',
            message: text,
            link: index === 1 ? LINKS.PERSONAL_DATA : undefined,
            linkText: t('PRICE_SUGGESTION_CHAT.PRESS_HERE').toLowerCase()
          };
          return newMessage;
        }) ?? []
    },
    {
      placeholder: () => '',
      key: 'postalCode',
      messages:
        priceSuggestionChatContent?.postalCodeTexts?.map((text) => {
          const newMessage: Message = {
            id: uuid(),
            type: 'recipient',
            messageType: 'scripted',
            contentType: 'text',
            message: text
          };
          return newMessage;
        }) ?? []
    },
    {
      placeholder: (count?: number) =>
        t('PRICE_SUGGESTION_CHAT.RESIDENTS_INCLUDING_YOU', {
          count
        }),
      key: 'residents',
      messages:
        priceSuggestionChatContent?.residentTexts?.map((text) => {
          const newMessage: Message = {
            id: uuid(),
            type: 'recipient',
            contentType: 'text',
            messageType: 'scripted',
            message: text
          };
          return newMessage;
        }) ?? []
    }
  ];

  return {
    loading,
    error,
    messages:
      messages.map((item, index) => ({ ...item, step: index + 1 })) ?? [],
    accomodationType
  };
};
