import { useCallback, useState } from 'react';

import { useRouter } from 'next/router';

import { mutations } from '@core/api';
import env from '@core/env-config';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { useLocation } from '@lib/utils';

import usePaymentQueryParameters from './usePaymentQueryParameters';

type PaymentParams = {
  quoteId: number;
  payLater?: boolean;
};

const usePaymentLink = () => {
  const { location } = useLocation();
  const [paymentId, setPaymentId] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const queryParams = usePaymentQueryParameters();
  const { push } = useRouter();

  const { useCreatePayment } = mutations.payment;
  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');

  const { mutate: createPayment, isLoading } = useCreatePayment({
    onError: (error) => {
      addMessage({
        message: tError('PAYMENT.CREATE_FAILED'),
        payload: error
      });
    },
    onSuccess: (data) => {
      const paymentLink = data.result.hostedPaymentPageUrl;
      setPaymentId(data.result.paymentId);
      if (paymentLink && location) {
        location.href = paymentLink + queryParams;
      } else {
        setError(tError('PAYMENT.CREATE_FAILED'));
      }
    }
  });

  const pay = useCallback(
    async ({ quoteId, payLater }: PaymentParams) => {
      if (payLater && quoteId) {
        push(`/onboarding/payment?quoteId=${quoteId}&payLater=true`);
      } else if (quoteId) {
        createPayment({
          quoteId,
          returnUrl: `${location?.origin}/onboarding/payment?quoteId=${quoteId}`,
          termsUrl: env.PAYMENT_TERMS_URL ?? ''
        });
      }
    },
    [push, createPayment, location]
  );

  return {
    error,
    isLoading,
    pay,
    paymentId
  };
};

export default usePaymentLink;
