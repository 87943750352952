import {
  CheckSimulationStatusResponse,
  mutations,
  useErrorCodeTranslation
} from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { PriceData, UserInfo } from '@core/types';
import { isNumber } from '@lib/utils';

import {
  updatePriceStore,
  useSelectFromPriceStore,
  useSelectFromUserStore
} from '../stores';

const useDiscountCode = (onSuccess?: () => void) => {
  const user = useSelectFromUserStore((state: UserInfo) => state);
  const priceStore = useSelectFromPriceStore((state: PriceData) => state);
  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');
  const { getErrorTranslation } = useErrorCodeTranslation();

  const { saveDiscountCode, removeDiscountCode } = storage();

  const handleSimulationSuccess = (
    data: CheckSimulationStatusResponse & {
      result: {
        discountCode?: string | undefined;
      };
    }
  ) => {
    if (data?.result) {
      const {
        monthlyPremium,
        premiumAmount,
        currency,
        discountMonthlyPremium,
        discountPremiumAmount,
        discountCode,
        isValidSimulation
      } = data.result;

      const priceData: PriceData = {
        ...priceStore,
        price: premiumAmount,
        monthlyPrice: monthlyPremium,
        discountMonthlyValue: discountMonthlyPremium,
        discountValue: discountPremiumAmount,
        currency,
        discountCode: isNumber(discountPremiumAmount)
          ? discountCode
          : undefined,
        isEstimating: false
      };
      updatePriceStore('updatePrice')({ ...priceData });

      if (discountCode) {
        saveDiscountCode(discountCode);
      } else {
        removeDiscountCode();
      }

      if (isValidSimulation) {
        if (discountCode && !isNumber(discountPremiumAmount)) {
          updateStoreDiscountCode(undefined);
          removeDiscountCode();
          addMessage({
            message: tError('INVALID_DISCOUNT_CODE'),
            payload: tError('INVALID_DISCOUNT_CODE')
          });
        } else {
          onSuccess?.();
        }
        return;
      }
    }
  };

  const { mutate, isLoading, isSuccess } = mutations.quotes.useSimulateQuote();

  const updateStoreDiscountCode = (discountCode?: string) => {
    updatePriceStore('updatePrice')({
      discountCode
    });
  };

  const setDiscountCode = (discountCode?: string) => {
    mutate(
      {
        age: user.age,
        postalCode: user.postalCode,
        numberOfResidents: user.residents,
        discountCode
      },
      {
        onSuccess: (data) =>
          handleSimulationSuccess({
            ...data,
            result: {
              ...data.result,
              discountCode
            }
          }),
        onError: (error) => {
          addMessage({
            message: getErrorTranslation(error?.data?.code),
            payload: error
          });
        }
      }
    );
  };

  return {
    setDiscountCode,
    isSuccess,
    isLoading
  };
};

export default useDiscountCode;
