import { useCallback, useEffect, useState } from 'react';

import { mutations, queries } from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { logger } from '@lib/logger';
import { useLocation, useToggle } from '@lib/utils';

import { authenticateWithBankId } from '../../lib/auth/authenticateWithBankId';
import { AuthResponse } from '../../lib/auth/types';

const sessionStorage = storage();

const useAuthentication = () => {
  const { addMessage } = useMessaging();

  const tError = useTranslation('ERROR');
  const { location } = useLocation();
  const { state: isLoading, open: showLoader, close: hideLoader } = useToggle();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const { useCreateToken } = queries.onboard;
  const { useValidateToken } = mutations.onboard;
  const updateToken = (accessToken?: string) => {
    if (accessToken) {
      sessionStorage.saveAccessToken(accessToken);
      setIsAuthenticated(true);
    }
  };
  const [assentlyToken, setAssentlyToken] = useState<string | undefined>();
  const { refetch } = useCreateToken({
    parameters: {
      hostUrl: location?.origin || '',
      provider: 'se-bankid'
    },
    options: {
      enabled: false,
      onError: (error) => {
        logger.error('Create token error', error);
        addMessage({
          message: tError('AUTH.CREATE_TOKEN_FAILED'),
          payload: error
        });
      }
    }
  });
  const { mutate: validateToken } = useValidateToken({
    onSuccess: (data) => updateToken(data?.result?.accessToken),
    onError: (error) => {
      logger.error('Validate token error', error);
      addMessage({
        message: tError('AUTH.VALIDATE_TOKEN_FAILED'),
        payload: error
      });
    }
  });

  const authCallback = useCallback(
    (response: AuthResponse) => {
      if (response.type === 'authenticated' && response.success) {
        const { success, type, token, provider, transactionId } = response;
        validateToken({
          success,
          type,
          identityToken: token,
          provider,
          transactionId
        });
        return;
      }

      setIsAuthenticated(false);
    },
    [validateToken]
  );

  useEffect(() => {
    const onError = (response: AuthResponse) => {
      const { type } = response;

      let errorMessage = '';

      if (type === 'cancelled') {
        errorMessage = tError('AUTH.USER_CANCELLED');
        hideLoader();
      }

      if (type === 'error') {
        errorMessage = tError('UNEXPECTED_ERROR');
        hideLoader();
      }

      if (type === 'failed') {
        errorMessage = tError('AUTH.FAILED');
        hideLoader();
      }

      logger.error(
        `useAuthentication error with type ${type}. Response: `,
        response
      );

      if (errorMessage) {
        addMessage({
          message: errorMessage
        });
      }

      setAssentlyToken(undefined);
    };

    if (assentlyToken) {
      authenticateWithBankId(assentlyToken, authCallback, onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assentlyToken, authCallback, tError]);

  const authenticate = useCallback(async () => {
    showLoader();
    const { data, error } = await refetch();
    if (error) {
      addMessage({
        message: tError('UNEXPECTED_ERROR'),
        payload: error
      });
      logger.error('Authenticate error: ', error);
      hideLoader();
      return;
    }

    if (data?.result) {
      sessionStorage.saveAuthKey(data.result.authorizationKey);
      setAssentlyToken(data.result.authorizationKey);
    }
  }, [refetch]);

  return { authenticate, isAuthenticated, isLoading };
};

export default useAuthentication;
