import { GetTemplateResponse } from '@core/api';

export const useTemplateMock: GetTemplateResponse = {
  result: {
    productId: '1',
    userInfo: {
      firstName: 'Kalle',
      lastName: 'Svensson',
      dateOfBirth: '1995-01-01',
      emailId: 'kalle@gmail.se',
      isEmailVerified: false,
      countryCallingCode: 'se',
      mobileNumber: '070707070'
    },
    homeData: {
      isPostalCodeMatch: true,
      postalCode: 41234,
      address: 'Hamngatan 12',
      city: 'Göteborg',
      numberOfResidents: 1,
      policyStartDate: '2023-06-01',
      paymentPeriodId: 1,
      residentsList: [{ personalNumber: '19950101' }],
      paymentPeriodList: [
        {
          value: 1,
          name: 'Yearly'
        },
        {
          value: 2,
          name: 'Monthly'
        }
      ]
    },
    premium: 1200,
    monthlyPremium: 100,
    currency: 'SEK'
  }
};

export const createTokenMock = {
  result: {
    authorizationKey: 'mocked-key'
  }
};
