import { twMerge } from 'tailwind-merge';

import { Accordion, IconType } from '@components/index';
import { useCommonQuestionsBlockContent } from '@core/content-management';

import InformationBlock from './InformationBlock';

type Props = {
  containerClassName?: string;
  titleClassName?: string;
  accordionTitleClassName?: string;
  accordionIconClassName?: string;
  accordionTextClassName?: string;
  dividerClassName?: string;
  expandedIcon?: IconType;
  unexpandedIcon?: IconType;
};

const CommonQuestionsBlock = ({
  containerClassName,
  dividerClassName,
  accordionIconClassName,
  accordionTitleClassName,
  accordionTextClassName,
  expandedIcon,
  titleClassName,
  unexpandedIcon
}: Props) => {
  const { data: commonQuestionsData } = useCommonQuestionsBlockContent();

  const commonQuestionBlock = commonQuestionsData?.fields;
  const dividerClasses = () =>
    twMerge('border-b-2 md:py-10 py-6', dividerClassName);

  return (
    <InformationBlock
      title={commonQuestionBlock?.title}
      className={containerClassName}
      titleClassName={titleClassName}
    >
      <div className="md:max-w-[1216px]">
        {commonQuestionBlock?.dropdownBlocks.map((item) => (
          <div key={item?.fields.title} className={dividerClasses()}>
            <Accordion
              title={item?.fields.title ?? ''}
              content={item?.fields.subtitle}
              expandedIcon={expandedIcon}
              unexpandedIcon={unexpandedIcon}
              titleClassName={accordionTitleClassName}
              iconClassName={accordionIconClassName}
              textClassName={accordionTextClassName}
            />
          </div>
        ))}
      </div>
    </InformationBlock>
  );
};

export default CommonQuestionsBlock;
