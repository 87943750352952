import env from '@core/env-config';
import { createRequestCreatorForApi, axiosWithAuth } from '@lib/api';

import { IIBError } from './types';

const commonPaths = [
  'Common/CheckSimulationStatusData',
  'Common/CheckEmailVerificationStatus'
] as const;

const quotesPaths = [
  'Quotes/TryAndPlay',
  'Quotes/Template',
  'Quotes/Calculate',
  'Quotes/Create',
  'Quotes/GetInitiate',
  'Quotes/SendEmailVerificationLink',
  'Quotes/GetQuoteDetailsById',
  'Quotes/QuoteToPolicy',
  'Quotes/GetAllList',
  'Quotes/GetViewById'
] as const;

// const usersPath = ['Onboard/CreateAuthorizationToken', 'Onboard/Authenticate', 'Onboard/GetPartyUserInfo'] as const;
const onboardPaths = [
  'Users/CreateToken',
  'Users/ValidateToken',
  'Users/GetPartyUserInfo'
] as const;

const paymentPaths = ['Payment/Create', 'Payment/v1/RegisterMerchant'] as const;

const productsPaths = ['Products', 'Products/GetProductList'] as const;

const policiesPaths = ['Policies'] as const;

const paths = [
  ...commonPaths,
  ...quotesPaths,
  ...onboardPaths,
  ...paymentPaths,
  ...productsPaths,
  ...policiesPaths
] as const;

const INSURANCE_IN_A_BOX_API = {
  baseUrl: env.INSURANCE_IN_A_BOX_URL || '',
  paths,
  //TODO: resolve env variable issues
  headers: {
    apiKey: env.INSURANCE_IN_A_BOX_KEY || ''
  },
  axiosClient: axiosWithAuth
} as const;
type INSURANCE_IN_A_BOX_API = typeof INSURANCE_IN_A_BOX_API;

export const createRequest = createRequestCreatorForApi<
  INSURANCE_IN_A_BOX_API,
  IIBError
>(INSURANCE_IN_A_BOX_API);
