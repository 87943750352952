import storage from '@core/storage';
import { PriceData } from '@core/types';
import { createStore } from '@lib/state-management';

const { savePriceStore } = storage();

const initialState: PriceData = {
  price: 0,
  monthlyPrice: 0,
  discountValue: undefined,
  discountMonthlyValue: undefined,
  currency: 'SEK',
  discountCode: undefined,
  paymentPeriodId: 2,
  startDate: new Date().toISOString(),
  isEstimating: false
};

const getInitialState = (): PriceData => {
  const sessionData = storage().price;

  const state = {
    ...initialState,
    ...(sessionData ? JSON.parse(sessionData) : {})
  };

  return state;
};

const updateFunctions = {
  updatePrice: (price: Partial<PriceData>) => (state: PriceData) => {
    savePriceStore({ ...state, ...price });

    return price;
  },
  resetPrice: () => () => {
    return initialState;
  }
};

export const {
  useSelectFromStore: useSelectFromPriceStore,
  updateStore: updatePriceStore,
  resetStore: resetPriceStore
} = createStore(getInitialState(), updateFunctions);
