import { createEventTracker } from '@core/tracking';

export type OnboardingEvent =
  | 'web_enter_is_renting'
  | 'web_enter_info'
  | 'web_read_terms'
  | 'web_apartment_selected'
  | 'web_enter_age'
  | 'web_enter_postal_code'
  | 'web_enter_residents'
  | 'web_enter_email'
  | 'web_price_suggestion'
  | 'web_price_suggestion_version_2'
  | 'web_bankid_identification'
  | 'web_address_confirmation'
  | 'web_select_start_date'
  | 'web_soft_wall'
  | 'web_finish_payment'
  | 'web_failed_payment'
  | 'web_denied_onboarding'
  | 'web_denied_onboarding_send'
  | 'web_denied_onboarding_skip'
  | 'web_cancelled_onboarding_send'
  | 'web_cancelled_onboarding_skip'
  | 'web_cancelled_onboarding'
  | 'web_order_complete';

export const useTrackEvent = createEventTracker<OnboardingEvent>('Onboarding');
