import { useEffect } from 'react';

import { mutations, CheckSimulationStatusRequest } from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { PriceData } from '@core/types';
import { isNumber } from '@lib/utils';

import {
  updateOnboardingStore,
  updatePriceStore,
  useSelectFromPriceStore
} from '../stores';

type Params = {
  age: number;
  postalCode: number;
  numberOfResidents: number;
  discountCode?: string;
  email?: string;
  onSuccess?: () => void;
  onFail?: () => void;
};

const useSimulateQuote = ({
  age,
  postalCode,
  numberOfResidents,
  onSuccess,
  onFail
}: Params) => {
  const { addMessage } = useMessaging();
  const priceStore = useSelectFromPriceStore((state: PriceData) => state);
  const updateProgress = updateOnboardingStore('updateProgress');
  const tError = useTranslation('ERROR');

  const { discountCode, removeDiscountCode } = storage();

  const { mutate, isLoading, error } = mutations.quotes.useSimulateQuote({
    onError: (error) => {
      addMessage({
        message: tError('ONBOARDING.CALCULATING_FAILED'),
        payload: error
      });
      updateProgress({ step: 1, progressionStep: 0, totalProgressionSteps: 6 });
    },
    onSuccess: (data) => {
      if (data?.result) {
        const {
          isValidSimulation,
          monthlyPremium,
          premiumAmount,
          currency,
          discountMonthlyPremium,
          discountPremiumAmount
        } = data.result;

        const priceData: PriceData = {
          ...priceStore,
          price: premiumAmount,
          monthlyPrice: monthlyPremium,
          discountMonthlyValue: discountMonthlyPremium,
          discountValue: discountPremiumAmount,
          discountCode: discountPremiumAmount ? discountCode : undefined,
          currency,
          isEstimating: false
        };

        updatePriceStore('updatePrice')({ ...priceData });
        if (isValidSimulation) {
          if (!!discountCode && !isNumber(priceData.discountValue)) {
            addMessage({
              message: tError('INVALID_DISCOUNT_CODE'),
              payload: tError('INVALID_DISCOUNT_CODE')
            });
            removeDiscountCode();
          }
          onSuccess?.();
          return;
        } else {
          onFail?.();
        }
      }
    }
  });

  useEffect(() => {
    if (age && postalCode && numberOfResidents) {
      const body: CheckSimulationStatusRequest = {
        age: Number(age),
        postalCode: Number(postalCode),
        numberOfResidents: Number(numberOfResidents),
        discountCode
      };
      mutate(body);
    } else {
      onFail?.();
    }
  }, [mutate, age, postalCode, numberOfResidents, onFail, discountCode]);

  return { isLoading, error };
};

export default useSimulateQuote;
