import { useEffect, useRef, useState } from 'react';

import { useFeature } from '@core/feature-toggles';
import { Message, OnboardingData } from '@core/types';
import { includes, isNumeric, isPostalCode, useWindow, uuid } from '@lib/utils';
import { useForm, z, zodResolver } from '@lib/validation';

import { SEND_DELAY } from './constants';
import { usePriceSuggestionSimplifiedChatMessages } from './usePriceSuggestionSimplifiedChatMessages';
import {
  updateOnboardingStore,
  useSelectFromOnboardingStore
} from '../stores/onboardingStore';
import { OnboardingEvent, useTrackEvent } from '../tracking';
import { useOnboardingTranslation } from '../translation';
import { ACCOMODATION_TYPE, OnboardingChatForm } from '../types';

export const STEPS = {
  PRICE_SUGGESTION_CHAT: 1,
  PRICE_SUGGESTION_SCREEN: 2,
  IDENTIFICATION: 3,
  ADDRESS_CONFIRMATION_CHAT: 4
} as const;

export type STEPS = (typeof STEPS)[keyof typeof STEPS];

export type PriceSuggestionFormValues = Record<
  keyof OnboardingChatForm,
  string
>;

type InformationLinks = '/din-integritet';

export const usePriceSuggestionChat = () => {
  const { hasWindow } = useWindow();
  const t = useOnboardingTranslation();

  const handleLinkClick = (text: InformationLinks) => {
    if (hasWindow) {
      window.open(text, '_blank');
    }
  };

  const orderChat: {
    step: number;
    placeholder: (count?: number) => string;
    key: keyof OnboardingChatForm;
    messages: Message[];
  }[] = [
    {
      step: 0,
      placeholder: () => '',
      key: 'age', // TODO: This hook should be refactored so that we don't need to set confusing key here
      messages: [
        {
          id: uuid(),
          type: 'recipient',
          contentType: 'text',
          message: t('IDENTIFICATION_SCREEN.CHAT_MESSAGE'),
          messageType: 'scripted'
        },
        {
          id: uuid(),
          type: 'recipient',
          contentType: 'text',
          message: t('IDENTIFICATION_SCREEN.CHAT_MESSAGE_2'),
          messageType: 'scripted'
        },
        {
          id: uuid(),
          type: 'sender',
          messageType: 'scripted',
          contentType: 'action',
          message: t('IDENTIFICATION_SCREEN.CHAT_MESSAGE_3'),
          action: () => handleLinkClick('/din-integritet'),
          link: '/din-integritet'
        }
      ]
    }
  ];

  const { step, progressionStep, totalProgressionSteps, totalSteps } =
    useSelectFromOnboardingStore((state: OnboardingData) => state);

  const sendMessage = (
    message: string,
    accomodationType?: ACCOMODATION_TYPE
  ) => {
    addMessage({
      message,
      id: `${Math.random()}`,
      type: 'sender',
      contentType: 'text',
      messageType: 'scripted'
    });
    const key = conversationData[progressionStep]?.key;
    const EVENT_BY_KEY: Record<keyof OnboardingChatForm, OnboardingEvent> = {
      accomodationType: 'web_apartment_selected',
      age: 'web_enter_age',
      postalCode: 'web_enter_postal_code',
      residents: 'web_enter_residents',
      isRenting: 'web_enter_is_renting'
    };

    if (key) {
      trackEvent(EVENT_BY_KEY[key]);
      handleChangeText(
        message.split(' ')[0],
        conversationData[progressionStep].key
      );
    }
    const isStepCompleted = progressionStep + 1 === totalProgressionSteps;

    // Skip isRenting step if accomodationType is apartment
    const shouldSkip =
      accomodationType === ACCOMODATION_TYPE.APARTMENT &&
      step === STEPS.PRICE_SUGGESTION_CHAT &&
      progressionStep === 0;

    updateProgress({
      step,
      totalSteps,
      totalProgressionSteps,
      progressionStep: progressionStep + 1 + (shouldSkip ? 1 : 0)
    });
    setPlaceholderForMessage('');
    setCanWrite(false);

    if (step + 1 < TOTAL_STEPS) {
      const timeout = setTimeout(() => {
        setMessages([]);
        if (isStepCompleted) {
          updateProgress({
            progressionStep: 0,
            step: isStepCompleted ? step + 1 : step,
            totalProgressionSteps: 0,
            totalSteps
          });
        }
        clearTimeout(timeout);
      }, SEND_DELAY);
    }
  };

  const {
    messages: conversationData,
    loading,
    accomodationType
  } = usePriceSuggestionSimplifiedChatMessages(sendMessage);

  const { trackEvent } = useTrackEvent();

  z.preprocess(
    (a) => parseInt(z.string().parse(a), 10),
    z.number().positive().max(100)
  );
  const schema = z.object({
    age: z.preprocess(
      (a) => parseInt(z.string().parse(a), 10),
      z
        .number({ invalid_type_error: t('VALIDATION.AGE.MUST_BE_AGE') })
        .gt(0, t('VALIDATION.AGE.MUST_BE_AGE'))
        .lte(150, t('VALIDATION.AGE.MUST_BE_AGE'))
    ),
    postalCode: z
      .string()
      .min(1, t('VALIDATION.REQUIRED'))
      .regex(isPostalCode, t('VALIDATION.ZIP.MUST_BE_ZIP_CODE')),
    residents: z
      .string()
      .min(1, t('VALIDATION.REQUIRED'))
      .regex(isNumeric, t('VALIDATION.RESIDENTS.MUST_BE_RESIDENTS'))
      .refine(
        (value) => {
          const numericValue = parseInt(value, 10);
          return numericValue >= 1 && numericValue <= 10;
        },
        {
          message: t('VALIDATION.RESIDENTS.MUST_BE_RESIDENTS')
        }
      )
  });

  const { control, resetField, watch } = useForm<PriceSuggestionFormValues>({
    mode: 'all',
    defaultValues: {
      accomodationType: '',
      isRenting: '',
      age: '',
      postalCode: '',
      residents: ''
    },
    resolver: zodResolver(schema)
  });

  const residents = watch('residents');

  const updateProgress = updateOnboardingStore('updateProgress');
  const [messages, setMessages] = useState<Message[]>([]);
  const [canWrite, setCanWrite] = useState<boolean>(false);
  const [placeholderForMessage, setPlaceholderForMessage] =
    useState<string>('');

  const [isDone, setIsDone] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [onboardingChatForm, setOnboardingChatForm] =
    useState<OnboardingChatForm>({} as OnboardingChatForm);

  const TOTAL_STEPS = conversationData.length;

  const handleChangeText = (text: string, name: keyof OnboardingChatForm) => {
    setOnboardingChatForm({ ...onboardingChatForm, [name]: text });
  };
  const { enabled: isPriceSuggectionV2enabled } = useFeature(
    'price-suggestion-version-2'
  );

  const addMessage = (message: Message) =>
    setMessages((prev) => [...prev, message]);

  useEffect(() => {
    const handleTrackEvent = (step: STEPS) => {
      // TODO: fix step logic to fix this ugly workaround.
      if (step === STEPS.IDENTIFICATION && progressionStep === 1) {
        trackEvent('web_address_confirmation');
      } else if (progressionStep === 0) {
        const EVENT_BY_STEP: Record<STEPS, OnboardingEvent> = {
          [STEPS.PRICE_SUGGESTION_CHAT]: 'web_enter_info',
          [STEPS.PRICE_SUGGESTION_SCREEN]: isPriceSuggectionV2enabled
            ? 'web_price_suggestion_version_2'
            : 'web_price_suggestion',
          [STEPS.IDENTIFICATION]: 'web_bankid_identification',
          [STEPS.ADDRESS_CONFIRMATION_CHAT]: 'web_address_confirmation'
        };
        trackEvent(EVENT_BY_STEP[step]);
      }
    };
    if (includes(Object.values(STEPS), step)) {
      handleTrackEvent(step);
    }
  }, [step, progressionStep]);

  useEffect(() => {
    let conversation: typeof conversationData = [];

    if (step === STEPS.PRICE_SUGGESTION_CHAT) {
      conversation = conversationData;
    }

    if (step === STEPS.PRICE_SUGGESTION_SCREEN) {
      return;
    }

    if (step === STEPS.IDENTIFICATION) {
      conversation = orderChat;
    }

    const currentStep = conversation[progressionStep];

    let timeout: ReturnType<typeof setTimeout>;

    if (currentStep && !loading) {
      if (messages.length < currentStep?.messages?.length) {
        timeout = setTimeout(() => {
          const message = currentStep.messages[messages.length];
          if (message) {
            addMessage(message as Message); // temporary solution
          }
        }, SEND_DELAY);
      } else if (messages.length === currentStep?.messages?.length) {
        timeout = setTimeout(() => {
          setPlaceholderForMessage(
            currentStep.placeholder(
              currentStep.key === 'residents'
                ? Number(watch('residents'))
                : undefined
            )
          );
          if (!canWrite) {
            setCanWrite(!(progressionStep === 0 && step === 1));
          }
        }, SEND_DELAY);
      }
    } else if (progressionStep >= TOTAL_STEPS) {
      timeout = setTimeout(() => {
        setIsDone(true);
      }, SEND_DELAY);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    TOTAL_STEPS,
    canWrite,
    // conversationData, // Causes infinite loop, TODO: find out why
    loading,
    messages.length,
    progressionStep,
    step,
    residents
  ]);

  const isIdentificationStep =
    step === STEPS.IDENTIFICATION && progressionStep === 0;

  const handleResetField = () => {
    if (STEPS.PRICE_SUGGESTION_CHAT === 1) {
      const substeps = [
        'isRenting',
        'accomodationType',
        'age',
        'postalCode',
        'residents'
      ] as const;
      resetField(substeps[progressionStep]);
    }
  };

  const goBack = () => {
    handleResetField();
    if (isIdentificationStep) {
      setMessages([]);
      updateProgress({
        step: STEPS.PRICE_SUGGESTION_SCREEN,
        progressionStep: 1,
        totalProgressionSteps: 0,
        totalSteps
      });
      return;
    }

    const prevStep = conversationData[progressionStep - 1];

    setPlaceholderForMessage('');
    setCanWrite(false);
    if (prevStep) {
      let newProgressionStep = progressionStep - 1;
      let newTotalProgressionSteps = totalProgressionSteps;

      // SKIP isRenting step if accomodationType is apartment
      if (
        accomodationType === ACCOMODATION_TYPE.APARTMENT &&
        progressionStep === 2
      ) {
        newProgressionStep = progressionStep - 2;
      }

      // To reflect the house extra step in the progression
      if (
        accomodationType === ACCOMODATION_TYPE.HOUSE &&
        progressionStep === 2
      ) {
        newTotalProgressionSteps = totalProgressionSteps + 1;
      }

      setMessages([]);
      updateProgress({
        progressionStep: newProgressionStep,
        totalSteps,
        totalProgressionSteps: newTotalProgressionSteps,
        step
      });
    }
  };

  const getKeyboardTypeByName = () => {
    const name = conversationData[progressionStep]?.key;
    const KEYBOARD_TYPE_BY_NAME: Record<
      keyof OnboardingChatForm,
      'text' | 'tel'
    > = {
      age: 'tel',
      postalCode: 'tel',
      residents: 'tel',
      isRenting: 'text',
      accomodationType: 'text'
    };
    return KEYBOARD_TYPE_BY_NAME[name as keyof OnboardingChatForm];
  };

  return {
    messages,
    loading,
    step,
    totalSteps: TOTAL_STEPS,
    canWrite,
    placeholderForMessage,
    isDone,
    sendMessage,
    goBack: (progressionStep > 0 || isIdentificationStep) && goBack,
    onboardingChatForm,
    control,
    resetField,
    key: conversationData[progressionStep]?.key,
    name:
      (conversationData[progressionStep]?.key as keyof OnboardingChatForm) ??
      'email',
    defaultValue: '',
    inputRef,
    keyboardType: getKeyboardTypeByName()
  };
};
