import {
  MutationFunction,
  QueryClient,
  useMutation as useMutationOriginal,
  useQueryClient
} from '@tanstack/react-query';

import { DefaultError } from '@lib/api';

import {
  MutationOptions,
  QueryKeyDefinition,
  UseMutationOptions
} from './types';

const mergeMutationOptions = <ReturnType, Variables, ErrorType>(
  options1: Omit<
    UseMutationOptions<ReturnType, Variables, ErrorType>,
    'request'
  > = {},
  options2: Omit<
    UseMutationOptions<ReturnType, Variables, ErrorType>,
    'request'
  > = {}
) => ({
  ...options1,
  ...options2,
  onSuccess: (
    data: ReturnType,
    variables: Variables,
    queryClient: QueryClient
  ) => {
    options1.onSuccess?.(data, variables, queryClient);
    options2.onSuccess?.(data, variables, queryClient);
  },
  onError: (error: ErrorType) => {
    options1.onError?.(error);
    options2.onError?.(error);
  }
});

const useMutation = <Result, Variables, ErrorType = DefaultError>({
  request,
  onSuccess,
  onError
}: UseMutationOptions<Result, Variables, ErrorType>) => {
  const queryClient = useQueryClient();

  return useMutationOriginal(request, {
    onSuccess: (data, variables) => onSuccess?.(data, variables, queryClient),
    onError
  });
};

const createMutationFromRequest =
  <ReturnType, Variables, ErrorType>(
    request: MutationFunction<ReturnType, Variables>,
    defaultOptions: Omit<
      UseMutationOptions<ReturnType, Variables, ErrorType>,
      'request'
    > = {}
  ) =>
  (
    options: Omit<
      UseMutationOptions<ReturnType, Variables, ErrorType>,
      'request'
    > = {}
  ) =>
    useMutation({ request, ...mergeMutationOptions(defaultOptions, options) });

// TODO: Fix errortype, see createRequest.ts for more info
export const createMutation = <
  QueryKey extends QueryKeyDefinition<unknown>,
  Result,
  Variables,
  ErrorType
>({
  request,
  dependentQueries = [],
  updateQueryStateOnSuccess,
  onError
}: MutationOptions<QueryKey, Result, Variables, ErrorType>) =>
  createMutationFromRequest(request, {
    onSuccess: (data, variables, queryClient) => {
      if (!updateQueryStateOnSuccess) {
        dependentQueries.forEach((query) => {
          queryClient.invalidateQueries([query]);
        });
        return;
      }
      updateQueryStateOnSuccess?.(queryClient, data, variables);
    },
    onError
  });
