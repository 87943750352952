import { Fragment } from 'react';

import { Divider, TextWithLabel } from '@components/index';
import { useImportantDocumentBlock } from '@core/content-management';

import {
  POLICY_AGREEMENT_PDF,
  PRE_SALE_INFO_PDF,
  PRODUCT_FACT_PDF
} from './constants';
import { useOnboardingTranslation } from '../../translation';
import InformationBlock from '../InformationBlock';
import { ContentItem } from '../types';

const ImportantDocumentsBlock = () => {
  const t = useOnboardingTranslation();
  const { data: ImportantDocumentBlockData } = useImportantDocumentBlock();
  const importantBlockDocumentContent = ImportantDocumentBlockData?.fields;

  const content: ContentItem[] = [
    {
      id: '1',
      title: importantBlockDocumentContent?.prePurchaseInformationText ?? '',
      url: PRE_SALE_INFO_PDF
    },
    {
      id: '2',
      title: importantBlockDocumentContent?.productFactSheetText ?? '',
      url: PRODUCT_FACT_PDF
    },
    {
      id: '3',
      title: importantBlockDocumentContent?.termsText ?? '',
      url: POLICY_AGREEMENT_PDF
    }
  ];

  const handleOpenUrl = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <InformationBlock
      title={t('PRICE_SUGGESTION_SCREEN.IMPORTANT_DOCUMENTS')}
      titleClassName="md:text-[40px] md:pb-8 pb-2"
      containerClassName="gap-2"
    >
      {content.map((item) => (
        <Fragment key={item.id}>
          <TextWithLabel
            text={item.title}
            onClick={() => handleOpenUrl(item.url)}
            icon="question"
          />
          <Divider />
        </Fragment>
      ))}
    </InformationBlock>
  );
};

export default ImportantDocumentsBlock;
