import { isInApp, isMobile } from '@core/device-detect';

import { AuthResponse } from './types';

export const authenticateWithBankId = (
  authToken: string,
  parentCallback: (response: AuthResponse) => void,
  onError: (response: AuthResponse) => void
) => {
  const _isInApp = isInApp();
  const _isMobile = isMobile();

  const autoStart = _isMobile && !_isInApp;

  const token = authToken;

  window.coreid_client.init({
    config: {
      allowedEids: ['se-bankid'],
      mode: 'auth',
      language: 'sv',
      provider: 'se-bankid',
      providerSettings: {
        'se-bankid': {
          autoStart,
          redirectMode: autoStart ? 'always' : 'never'
        }
      }
    },
    token: `${token}`,
    callback: function (callbackResponse: AuthResponse) {
      if (callbackResponse.type === 'authenticated') {
        parentCallback(callbackResponse);
        window.coreid_client.close();
      } else if (
        ['failed', 'cancelled', 'error'].includes(callbackResponse.type)
      ) {
        onError(callbackResponse);
      }
    }
  });

  window.coreid_client.start();
};
