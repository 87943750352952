import env from '@core/env-config';
import { createRequestCreatorForApi } from '@lib/api';

const offerPaths = ['cancelled', 'denied'];

const paths = [...offerPaths] as const;

const SEJFA_API = {
  baseUrl: env.SEJFA_API_URL ?? '',
  paths
} as const;

export const createRequest = createRequestCreatorForApi(SEJFA_API);
