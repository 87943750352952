import { useRouter } from 'next/router';

export const useDiscountCodeFromUrl = () => {
  const router = useRouter();
  const discountCodeFromURL = router.query['discount'];

  return {
    discountCodeFromURL,
    isDiscountCodeProvided: typeof discountCodeFromURL === 'string'
  };
};
