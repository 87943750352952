import { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
} & PropsWithChildren;

const Modal = ({ className, children }: Props) => {
  const classes = twMerge(
    'fixed inset-0 flex items-end  md:items-center justify-center bg-black bg-opacity-60 text-white md:py-8 z-20 animate-fade-in',
    className
  );
  return <div className={classes}>{children}</div>;
};

export default Modal;
