import React, { useState } from 'react';

import { Button, Divider, TextInput } from '@components/index';
import { useTranslation } from '@core/translation';
import { UserInfoFormValues } from '@core/types';

import InformationBlock from './InformationBlock';
import { useOnboardingTranslation } from '../translation';

type YourInformationBlockProps = {
  age: number;
  postalCode: number;
  residents: number;
  onSubmit?: (formValues: UserInfoFormValues) => void;
};

const YourInformationBlock = ({
  age,
  postalCode,
  residents,
  onSubmit
}: YourInformationBlockProps) => {
  const t = useOnboardingTranslation();
  const ct = useTranslation('COMMON');

  const [formValues, setFormValues] = useState<UserInfoFormValues>({
    age,
    postalCode,
    residents
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof UserInfoFormValues
  ) => {
    setFormValues((prev) => ({ ...prev, [key]: event.target.value }));
  };

  const handleSubmit = () => {
    onSubmit?.(formValues);
  };

  const checkIfFormValuesAreInvalid = () => {
    if (!formValues.age || !formValues.postalCode || !formValues.residents) {
      return true;
    }

    return (
      formValues.age === age &&
      formValues.postalCode === postalCode &&
      formValues.residents === residents
    );
  };

  return (
    <InformationBlock
      title={t('PRICE_SUGGESTION_SCREEN.CHANGE_YOUR_INFO')}
      titleClassName="md:mb-4 my-6"
    >
      <TextInput
        floatingLabel={t('PRICE_SUGGESTION_SCREEN.AGE')}
        value={formValues.age}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(event, 'age')
        }
        className="text-xl md:text-[32px]"
        type="number"
        icon="edit"
      />
      <Divider />
      <TextInput
        floatingLabel={t('PRICE_SUGGESTION_SCREEN.POSTAL_CODE')}
        value={formValues.postalCode}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(event, 'postalCode')
        }
        className="text-xl md:text-[32px]"
        type="number"
        icon="edit"
      />
      <Divider />
      <TextInput
        floatingLabel={t('PRICE_SUGGESTION_SCREEN.RESIDENTS')}
        value={formValues.residents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(event, 'residents')
        }
        className="text-xl md:text-[32px]"
        type="number"
        icon="edit"
      />
      {typeof handleSubmit === 'function' && (
        <div className="mt-12 flex space-x-4">
          <Button
            disabled={checkIfFormValuesAreInvalid()}
            variant="primary"
            className="h-[56px] w-full text-black"
            hugged
            onClick={handleSubmit}
          >
            {ct('SAVE')}
          </Button>
        </div>
      )}
    </InformationBlock>
  );
};

export default YourInformationBlock;
