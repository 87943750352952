import storage from '@core/storage';
import { useToggle } from '@lib/utils';

import {
  EditUserInformationModal,
  EstimatingModal,
  InsuranceProviderModal
} from '../components';
import InformationListB from '../components/InformationListB';
import PriceSuggestionBlock from '../components/PriceSuggestionBlock';
import { useOnboardingNavigation } from '../navigation';
import { updatePriceStore, useSelectFromPriceStore } from '../stores';
import { usePriceSuggestionTranslation } from '../translation';

type Props = {
  onContinueClick: () => void;
  isLoading: boolean;
};

const PriceSuggestionPageB = ({ onContinueClick, isLoading }: Props) => {
  const priceStore = useSelectFromPriceStore((state) => state);

  const {
    monthlyPrice,
    price,
    paymentPeriodId,
    discountMonthlyValue,
    discountValue,
    isEstimating
  } = priceStore;

  const { discountCode } = storage();

  const pt = usePriceSuggestionTranslation();
  const { state: isEditing, toggle: toggleEditing } = useToggle();

  const {
    state: isInsuranceProviderModalVisible,
    toggle: toggleInsuranceProviderModal
  } = useToggle();

  const { navigateToOnboardingFailed } = useOnboardingNavigation();
  const fullPrice = price + (discountValue ?? 0);
  const fullMonthlyPrice = monthlyPrice + (discountMonthlyValue ?? 0);

  return (
    <div className="no-scrollbar grid h-full gap-0.5 overflow-auto md:grid-cols-2 md:grid-rows-1 md:overflow-hidden">
      <PriceSuggestionBlock
        isButtonLoading={isLoading}
        onUpdateUserInfo={toggleEditing}
        price={paymentPeriodId === 1 ? String(price) : String(monthlyPrice)}
        discountCode={discountCode}
        insuranceInfo={pt('INSURANCE_INFO')}
        toggleInsuranceProviderModal={toggleInsuranceProviderModal}
        priceUnit={paymentPeriodId === 1 ? pt('PRICE_YEAR') : pt('PRICE_MONTH')}
        fullPrice={
          paymentPeriodId === 1 ? String(fullPrice) : String(fullMonthlyPrice)
        }
        onContinueClick={onContinueClick}
        isDiscountCodeEditable
        buttonText={pt('GET_YOUR_INFO_WITH_BANK_ID')}
      />
      <InformationListB />
      {isEditing && (
        <EditUserInformationModal
          onClose={toggleEditing}
          onSubmit={() =>
            updatePriceStore('updatePrice')({
              isEstimating: true
            })
          }
        />
      )}
      {isEstimating && (
        <EstimatingModal
          onEstimationFailed={() => navigateToOnboardingFailed('failed')}
        />
      )}
      {isInsuranceProviderModalVisible && (
        <InsuranceProviderModal onClose={toggleInsuranceProviderModal} />
      )}
    </div>
  );
};

export default PriceSuggestionPageB;
