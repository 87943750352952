import { getPoliciesList } from '../requests';
import { createQuery } from '../store';

const useGetPoliciesList = createQuery('getPoliciesList', getPoliciesList);

const policies = {
  useGetPoliciesList
};

export default policies;
